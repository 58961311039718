import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/content/Home/Home';
import './App.scss';
import Dashboard from './components/content/Dashboard/Dashboard';
import SessionProtectedComponent from './components/common/SessionProtectedComponent';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Login from './components/content/Login/Login';
import Results from './components/content/Results/Results';
import { useEffect } from 'react';
import SurveyTrial from './components/content/SurveyTrial/SurveyTrial';
import DefaultSurveyContainer from './components/content/Survey/DefaultSurveyContainer';
import { PopupContainer } from './components/popup/PopupContainer';
import Register from './components/content/Register/Register';

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
      <div className="app">
        <PopupContainer />

        <Header />

        <div className='content'>
          <Routes>
            <Route path="/" default element={<Home />} />
            <Route path="/home" default element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/survey-trial" element={<SurveyTrial />} />
            <Route path="/dashboard" element={<SessionProtectedComponent element={<Dashboard />} />} />
            <Route path="/survey" element={<SessionProtectedComponent element={<DefaultSurveyContainer />} />} />
            <Route path="/results" element={<SessionProtectedComponent element={<Results />} />} />
          </Routes>
        </div>
        <Footer />
      </div>
  );
}

export default App;
