import { useState } from 'react';
import './Login.scss';
import { login } from '../../../api/session';
import { storeSession } from '../../../store/reducer';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../store/store';

export default () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [, dispatch] = useStore();

    const submit = (e) => {
        e.preventDefault();
        login(email, password)
            .then(({ data }) => {
                dispatch(storeSession(data));
                navigate('/dashboard')
            })
    };

    return (
        <div className="login main-container">
            <div className="login-container">
                <h1>Se connecter</h1>
                <form onSubmit={submit}>
                    <input
                        id="email"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        placeholder="Adresse email"
                        required
                        autoFocus
                    />

                    <input
                        id="password"
                        name="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                        placeholder="Créer un mot de passe"
                        required
                    />

                    <button type="submit" style={{ width: "100%" }} className="btn-opaque">Connexion</button>
                </form>
                
                <div className='redirect-register small-text'>
                    <span className='subtle'>Vous n'avez pas de compte ?</span>
                    <a onClick={() => navigate('/register')}>Inscription</a>
                </div>
            </div>
        </div>
    )
};