import React from "react"
import './Home.scss';
import { getHomeFullLogoUrl, getHomeImageUrl } from "../../../sources/images";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

export default () => {
    const navigate = useNavigate();

    return (
        <div className="home main-container">
            <div className="home-img-container">
                <img src={getHomeImageUrl()} />
            </div>
            <div className="home-presentation-container">
                <div className="home-presentation">
                    <h1>Évaluation dans l'action</h1>
                    <div className="sub-text">de la qualité des pratiques et des compétences professionnelles</div>
                    <div className="home-buttons">
                        <div className="home-buttons-line">
                            <HashLink to="/home#commentRepondre">
                                <button className="btn-light">Comment répondre?</button>
                            </HashLink>
                        </div>
                        <div className="home-buttons-line">
                            <Link to={'/survey-trial?question=0'}>
                              <button className="btn-opaque">Essayer l'outil</button>
                            </Link>
                            <a>
                                <button className="btn-light">Commencer l'évaluation</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="commentRepondre" className="home-full-description">
                <div className="full-logo-container">
                    <img src={getHomeFullLogoUrl()} />
                </div>
                <div>
                  <h2>
                    Bienvenue dans E-value-action !
                  </h2>
                  <p>
                    E-value-action (EVA) est une plateforme d’évaluation dans
                    l’action permettant aux professionnels d'évaluer la conformité
                    des pratiques et leurs compétences à même leur contexte
                    travail.
                  </p>
                  <p>
                    <strong>ÉVALUEZ… ÉVOLUEZ !</strong>
                  </p>
                  <p>
                    EVA stimule à la fois l'apprentissage et l'amélioration continue.
                  </p>
                </div>
                <div className="homePageTextIntro">
                  <h3>
                    ESSAYER L'OUTIL
                  </h3>
                  <ul>
                    <li>
                    Des questions-test sont accessibles en tout temps
                    pour se familiariser avec le format du questionnaire.
                    </li>
                  </ul>

                  <h3>PÉRIODE D’ÉVALUATION</h3>
                  <ul>
                    <li>Procéder à votre inscription.</li>
                  </ul>
                  <ul>
                    <li>
                    Prendre connaissance de la thématique d&#39;évaluation ciblée pour votre équipe.
                    </li>
                  </ul>
                  <ul>
                    <li>
                    À partir d’un ordinateur, d&#39;une tablette ou d&#39;un cellulaire, répondre à l’outil directement sur votre lieu de travail.
                    </li>
                  </ul>
                  <ul>
                    <li>
                    Vous disposez d&#39;un mois (±30 jours) pour répondre.
                    </li>
                  </ul>
                  <ul>
                    <li>
                    L&#39;outil mesure les perceptions des professionnels, lesquelles sont basées sur une alliance d'objectivité et de  subjectivité.
                    </li>
                  </ul>
                  <ul>
                    <li>
                    Vos réponses doivent donc être basées sur toutes les
                    sources de données accessibles au travail, e.g. :
                    auditer un minimum de 2 dossiers pertinents actifs
                    ou d&#39;archives, discussions de cas entre pairs,
                    questionner la clientèle, parcourir les documents
                    administratifs à votre disposition...
                    </li>
                  </ul>

                  <h3>
                    1. Déclaration d&#39;un événement
                  </h3>

                  <ul>
                    <li>
                    Dès le constat d&#39;un événement significatif en lien
                    avec la thématique d&#39;évaluation, veuillez cliquer sur
                    le bouton "Déclarer un événement".
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Le professionnel ayant la responsabilité du cas (personne/dossier) lors du constat d'un événement significatif est le seul qui procède à la déclaration.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Soyez à l’aise de déclarer avec exactitude et sans culpabilité les événements constatés. EVA est votre alliée d'amélioration continue s'inscrivant dans une culture de non-blâme. 
                    </li>
                  </ul>
                  <ul>
                    <li>
                      La plateforme n'a pas de vocation légale, c'est pourquoi elle ne remplace en aucun cas les formulaires officiels en vigueur dans votre établissement. 
                    </li>
                  </ul>

                  <h3>
                    2. Évaluation dans l'action
                  </h3>
                  <ul>
                    <li>
                      Vous évaluerez les pratiques (savoir-faire) et les compétences (savoir-être, savoir) de votre équipe.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Vous évaluez du même coup vos propres pratiques et compétences en tant que membre de l'équipe. 
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Votre gestionnaire vous communiquera une période de référence pour l'évaluation (par exemple, le dernier mois, les derniers 3 mois, 6 mois, ou autre.) 
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Pour chacun des items, vous identifierez d'abord la situation actuelle sur un cadran circulaire de 0 à 100.
                    </li>
                  </ul>

                  <div className="info-container">
                    <p>
                      <span style={{ fontWeight: "bold", color: "#718b95" }}>
                        100%
                      </span>{" "}
                      signifie que la situation actuelle est <strong>totalement</strong> conforme avec ce qui est décrit dans l'énoncé
                    </p>

                    <p>
                      <span style={{ fontWeight: "bold", color: "#d02f27" }}>
                        0%
                      </span>{" "}
                      signifie que la situation actuelle n’est <strong>absolument pas</strong> conforme à ce qui est décrit dans l'énoncé
                    </p>
                  </div>
                  
                  <ul>
                    <li>
                      Vous identifierez ensuite la cible, c'est-à-dire, la situation souhaitée en fonction de la réalité particulière de votre contexte de travail.
                    </li>
                  </ul>
                  <p>
                    <b>Attention.</b> Sur un écran tactile, maintenir le doigt enfoncé pour manipuler la flèche du cadran circulaire.
                  </p>

                  <h3>
                    RÉTROACTION
                  </h3>

                  <ul>
                    <li>
                      Une rétroaction est disponible sur la plateforme dès la fermeture de la période d’évaluation (fin du mois).
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Deux types de résultats sont à consulter : 1) Fréquence des événements significatifs; 2) Conformité des pratiques et compétences professionnelles 
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Vos réponses demeureront confidentielles, il n’y a pas de façon de vous identifier individuellement, sauf par les concepteurs de la plateforme.
                    </li>
                  </ul>

                  <div className="info-container">
                    <h4>Interprétation des résultats</h4>
                    <p>
                      Vous constaterez les écarts de pratique et les besoins prioritaires en matière de développement des compétences.
                    </p>
                    <p>
                      <b>Plus l’écart entre la situation actuelle et la situation souhaitée (cible) est faible, plus la qualité d'une pratique ou la compétence est élevée.</b>
                    </p>
                    <p>
                      Ainsi, plus l’écart est élevé, plus la qualité ou la compétence est faible.
                    </p>
                  </div>

                  <h3>
                    PÉRIODE D'AMÉLIORATION
                  </h3>

                  <p>
                    EVA étant un processus de réflexion ancré dans l'action, l'apprentissage et l’amélioration s’effectuent en continu. Ceci favorise la conformité aux normes de pratique en vue de visites d'accréditation, d'ordres professionnels ou ministérielles. Toutefois, des initiatives structurées peuvent être déployées en dehors des périodes d’évaluation (comités d'amélioration, formation continue).
                  </p>

                  <h5 style={{ marginTop: "70px" }}>Notes.</h5>

                  <p style={{ fontSize: "12px" }}>
                    Pour toute question, contacter l'équipe de concepteurs au <a href="mailto:e.value.action@gmail.com">e.value.action@gmail.com</a>
                  </p>

                  <p style={{ fontSize: "12px" }}>
                    La plateforme EVA a été développée dans le cadre d’une démarche académique et scientifique. Son format s'inspire d'une philosophie systémique et de travaux dans le domaine de la psychologie et de la santé au travail. Pour plus d'informations, consulter le :
                    {" "}
                    <a
                      style={{ textDecoration: "underline", color: "#1a0cab" }}
                      target="_blank"
                      href="http://qualitedevie.lepsyq.ca/fr"
                    >
                      http://qualitedevie.lepsyq.ca/fr
                    </a>
                  </p>
                </div>
            </div>
        </div>
    );
}