import { useState } from 'react';
import './Register.scss';
import { storeSession } from '../../../store/reducer';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../store/store';
import { register } from '../../../api/user';

export default () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [, dispatch] = useStore();

    const submit = (e) => {
        e.preventDefault();
        register(email, password)
            .then(() => {
                navigate('/login');
            })
            .catch(() => {
                navigate('/home');
                alert('Une erreur est survenue. Contactez un administrateur si vous croyez avoir été enregistré');
            });
    };

    return (
        <div className="register main-container">
            <div className="register-container">
                <h1>Inscription</h1>
                <div className="small-text register-prerequisite">Notez qu'un administrateur doit avoir préalablement enregistré votre email afin de pouvoir vous inscrire.</div>
                <form onSubmit={submit}>
                    <input
                        id="email"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        placeholder="Adresse email"
                        required
                        autoFocus
                    />

                    <input
                        id="password"
                        name="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                        placeholder="Créer un mot de passe"
                        required
                    />

                    <button type="submit" style={{ width: "100%" }} className="btn-opaque">Connexion</button>
                </form>
                
                <div className='redirect-login small-text'>
                    <span className='subtle'>Vous avez déjà un compte ?</span>
                    <a onClick={() => navigate('/login')}>Se connecter</a>
                </div>
            </div>
        </div>
    )
};