export const survey = {
    title: "Exemple d'activité d'évaluation réflexive sur le leadership en gestion humaniste",
    questions: [
      {
        id: "1",
        title: "Les gestionnaires font preuve de bienveillance envers les personnes employées",
        description: "Cela signifie que les gestionnaires manifestent de l'empathie, de la sincérité, qu’ils traitent aimablement les membres de leur équipe, qu’ils ou elles protègent leur estime de soi, leur dignité, leur santé psychologique et physique. Ils et elles s'efforcent de favoriser l'équilibre travail vie-personnelle."
      },
      {
        id: "2",
        title: "Les gestionnaires considèrent chaque personne unique composant l'équipe",
        description: "Les gestionnaires accueillent la diversité, ils reconnaissent et mettent à profit les forces et expertises des membres de l'équipe. Ils font preuve de justice, d’équité, ils sont ouverts aux idées de chacun, ils sont attentifs aux particularités personnelles des membres de l'équipe."
      },
      {
        id: "3",
        title: "Les gestionnaires mettent en place les conditions favorisant l'autodépassement",
        description: "Cela signifie que les gestionnaires assistent les personnes employées dans leur développement professionnel, qu’ils offrent des opportunités et leur permettent la créativité, qu’ils les encouragent à participer à des projets mobilisants."
      },
      {
        id: "4",
        title: "La relation entre les personnes gestionnaires et employées se caractérise par une réciprocité des échanges (donnant-donnant)",
        description: "Les gestionnaires font preuve de reconnaissance par leurs paroles, leur non-verbal et leurs actions. Ils ou elles offrent aux personnes employées un environnement de travail sain et sécuritaire. Les membres de l’équipe savent qu’ils ou elles peuvent compter sur leur gestionnaire et inversement."
      },
      {
        id: "5",
        title: "Les gestionnaires mobilisent naturellement les équipes",
        description: "Les gestionnaires ont une vision claire du futur et la véhiculent efficacement, ils ou elles sont investi.e.s dans leur travail. Ils ou elles constituent des modèles de rôle, sont crédibles, authentiques, inspirant.e.s et innovent par leurs approches. Les gestionnaires assument les décisions prises, soutiennent le changement et font preuve de courage managérial devant l'adversité ce qui amène l'équipe à vouloir les suivre."
      }
  ]
};
