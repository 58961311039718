import { useLocation, useNavigate } from 'react-router-dom';
import './Header.scss';
import { useStore } from '../../store/store';
import { clearSession } from '../../store/reducer';
import { getFullHeaderLogoUrl, getSmallHeaderLogoUrl } from '../../sources/images';
import Hamburger from './Hamburger';

const LoggedIn = ({ location }) => {
    const [, dispatch] = useStore();
    const navigate = useNavigate();
    
    return (
        <>
            <a onClick={() => {
                dispatch(clearSession());
                navigate('/');
            }}>
                <button className="btn-light no-border">Se Deconnecter</button>
            </a>
            {
                location.pathname != '/dashboard' && (
                    <a onClick={() => navigate('/dashboard')}>
                        <button className="btn-light no-border">Mon Profile</button>
                    </a>
                )
            }
        </>
    );
};

const Actions = () => {
    const location = useLocation();
    const [state] = useStore();
    const navigate = useNavigate();

    if (location.pathname == '/login' || location.pathname == '/register') {
        return null;
    }

    if (state.session) {
        return <LoggedIn location={location} />;
    }

    return (
        <>
            <a onClick={() => navigate('/login')}>
                <button className="btn-light no-border">Connexion</button>
            </a>
            <a onClick={() => navigate('/register')}>
                <button className="btn-light no-border">S'inscrire</button>
            </a>
        </>
    );
};

export default () => {
    const actions = Actions();
    return (
        <div className="nav-bar-container main-container">
            <div className="nav-bar">
                <a href="/">
                    <img className="nav-logo full" src={getFullHeaderLogoUrl()} />
                    <img className="nav-logo small" src={getSmallHeaderLogoUrl()} />
                </a>
                <div className='right'>
                    <div className='big-screen'>
                        <Actions />
                    </div>
                    {actions && (
                        <Hamburger>
                            {actions}
                        </Hamburger>
                    )}
                </div>
            </div>
        </div>
    );
}